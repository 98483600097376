import React, { useEffect, useContext, useState } from "react";
import API from "api/API";
import AuthAPI from "api/AuthAPI";
import Storage from "utils/Storage";
import Cookie from "utils/Cookie";

import Loader from "components/general/Loader";

const AuthContext = React.createContext();

let refresh_timer;

export default function AuthContextProvider({ children }) {

	const [ loading, setLoading ] = useState( true );
	const [ user, setUser ] = useState( null );
   const is_admin = [ "company_admin", "super_admin" ].includes( user?.roles?.[0]?.name );

	const checkPermission = ( permission ) => {
		
		if ( Array.isArray( permission )) {

			for ( let i = 0; i < permission.length; i++ ) {
				if ( user.abilities.includes( permission[ i ])) return true;
			}

			return false;

		} else {
			return user.abilities.includes( permission ); 
		}
	}


	const refreshUser = async() => {
		const { data } = await AuthAPI.me();
		setUser( data );
	}


	const getUser = async() => {

		try {
			const { data } = await AuthAPI.me();
			setUser( data );

			setLoading( false );

		} catch ( e ) {
			logOut()
			return Promise.reject( e );
		}
	}


	const updateMe = async( _data_ ) => {
		const { data } = await AuthAPI.updateMe( _data_ );
		setUser({...user, ...data })
	}


	const runRefreshTimer = () => {
		const expires_at = +( Storage.get( "token_expires_at" ));
		if ( !expires_at || isNaN( expires_at )) return;

		const timeout = new Date( expires_at ) - new Date() - 10000;
		refresh_timer = setTimeout(() => refreshToken(), timeout );
	}


	const updateTokens = ({ access_token, refresh_token }) => {

		Storage.set( "access_token", access_token.token );
		Storage.set( "refresh_token", refresh_token.token );

      Cookie.setTokenCookie( access_token.token );

		clearTimeout( refresh_timer );

		const expires_at = +new Date( access_token.expires_at );
		if ( expires_at ) {
			Storage.set( "token_expires_at", expires_at );
			runRefreshTimer();	
		}
	}


	const logIn = async({ email, password }) => {

		const { data } = await AuthAPI.logIn({ email, password });

		updateTokens( data );
		getUser();
	}


	const logOut = () => {

		Cookie.removeTokenCookie();

		Storage.remove( "access_token" );
		Storage.remove( "refresh_token" );
		Storage.remove( "token_expires_at" );

		setUser( null );
		setLoading( false );
	}


	const refreshToken = async() => {

		const refresh_token = Storage.get( "refresh_token" );
		if ( !refresh_token ) return;

		const { data } = await AuthAPI.refresh( refresh_token );
		updateTokens( data );
	}


	useEffect(() => {
		
		API.interceptors.response.use( res => {
			if ( res?.data?.code === 401 ) logOut();
			return res;
		}, err => {
			
			const resp = err?.response;
			
			if ( resp?.status === 401 && !/\/auth/.test( resp?.config?.url )) logOut();
			return Promise.reject( err );
		});
	}, [])


	useEffect(() => {

		Cookie.removeTokenCookie();
		const access_token = Storage.get( "access_token" );
		
		if ( access_token ) {
			getUser()
			.then(() => {
            Cookie.setTokenCookie( access_token );
				runRefreshTimer();
			})
		} else {
			setLoading( false );
		}
	}, [])


	return loading ? <Loader style={{ margin: "auto" }}/> : (
		<AuthContext.Provider value={{
			user,
         is_admin,
			refreshUser,
			checkPermission,
			updateMe,
			logIn,
			logOut
		}}>
			{ children }
		</AuthContext.Provider>
	)
}


export const useAuthContext = () => useContext( AuthContext );

export const useUser = () => useAuthContext().user;

export const useIsAdmin = () => useAuthContext().is_admin;

export const useCheckPermission = () => useAuthContext().checkPermission;

export const useLogOut = () => useAuthContext().logOut;

export const useLogIn = () => useAuthContext().logIn;