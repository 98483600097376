import { lazy } from "react";
import { Route, Routes as Switch } from "react-router-dom";

import Paths from "./Paths";
import ProtectedRoute from "./ProtectedRoute";

const PublicIssueForm = lazy(() => import( "pages/Public/PublicIssueForm" ));
const CheckIssueStatus = lazy(() => import( "pages/Public/CheckIssueStatus" ));

const LogIn = lazy(() => import( "pages/Auth/LogIn" ));
const ForgotPassword = lazy(() => import( "pages/Auth/ForgotPassword" ));
const PasswordReset = lazy(() => import( "pages/Auth/PasswordReset" ))

const NewIssue = lazy(() => import( "pages/Issue/NewIssue" ));
const IssueList = lazy(() => import( "pages/Issue/IssueList" ));
const EditIssue = lazy(() => import( "pages/Issue/EditIssue" ));
const UserList = lazy(() => import( "pages/User/UserList" ));
const AddEditUser = lazy(() => import( "pages/User/AddEditUser" ));
const Profile = lazy(() => import( "pages/User/Profile" ));
const CompanyList = lazy(() => import( "pages/Company/CompanyList" ));
const AddEditCompany = lazy(() => import( "pages/Company/AddEditCompany" ));
const UnavailableDays = lazy(() => import( "pages/Settings/UnavailableDays" ));

const PublicPages = [
	{
		path: Paths.Public.IssueForm,
		Component: PublicIssueForm
	},
	{
		path: Paths.Public.CheckIssueStatus,
		Component: CheckIssueStatus
	}
]

const NotAuthPages = [
	{
		path: Paths.Auth.Login,
		Component: LogIn
	},
	{
		path: Paths.Auth.ForgotPassword,
		Component: ForgotPassword
	},
	{
		path: Paths.Auth.PasswordReset,
		Component: PasswordReset
	}
]

const NeedAuthPages = [
	{
		path: Paths.Issue.New,
		Component: NewIssue
	},
	{
		path: [ "/", Paths.Issue.List ],
		Component: IssueList
	},
	{
		path: Paths.Issue.Edit(),
		Component: EditIssue
	},
	{
		path: Paths.User.List,
		Component: UserList
	},
	{
		path: [ Paths.User.Add, Paths.User.Edit()],
		Component: AddEditUser
	},
	{
		path: Paths.Profile,
		Component: Profile
	},
	{
		path: Paths.Company.List,
		Component: CompanyList
	},
	{
		path: [ Paths.Company.Add, Paths.Company.Edit()],
		Component: AddEditCompany
	},
   {
      path: Paths.Settings.UnavailableDays,
      Component: UnavailableDays
   },
   {
      path: "*",
      Component: () => <div style={{
			paddingTop: 50,
			textAlign: "center"
		}}>
			<h1>
				Błąd 404. <br /> Strona nie istnieje.
			</h1>
		</div>
   }
]


export default function Routes() {
	return (
      <Switch>

         <Route path="/public"> 
            { PublicPages.map(({ path, Component }) => (
               <Route
                  key={ path }
                  path={ path }
                  Component={ Component }
               />
            ))}
         </Route>

         <Route element={ <ProtectedRoute/> }>
            { NotAuthPages.map(({ path, Component }) => (
               <Route
                  key={ path }
                  path={ path }
                  Component={ Component }
               />
            ))}
         </Route>

         <Route element={ <ProtectedRoute need_auth/> }>
            { NeedAuthPages.map(({ path, Component }) => (
               Array.isArray( path ) 
                  ? path.map( p => (
                     <Route
                        exact
                        key={ p }
                        path={ p } 
                        Component={ Component }
                     />
                  ))
                  : <Route
                     exact
                     key={ path }
                     path={ path } 
                     Component={ Component }
                  />
            ))}
         </Route>

      </Switch>
	)
}