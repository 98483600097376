export default {

	Public: {
		IssueForm: "/public/issue-form",
		CheckIssueStatus: "/public/check-issue-status"
	},

	Auth: {
		Login: "/login",
		ForgotPassword: "/forgot-password",
		PasswordReset: "/password-reset"
	},

	Issue: {
		List: "/issues",
		New: "/new-issue",
		Edit: ( id = ":id" ) => `/issues/${ id }`
	},

	Profile: "/profile",
	Statistics: "/statistics",

	Company: {
		List: "/companies",
		Add: "/companies/add",
		Edit: ( id = ":id" ) => `/companies/${ id }`
	},

	User: {
		List: "/users",
		Add: "/users/add",
		Edit: ( id = ":id" ) => `/users/${ id }`
	},

   Settings: {
      UnavailableDays: "/settings/unavailable-days"
   }
}