import { NavLink } from "react-router-dom";
import { useCheckPermission } from "context/AuthContext";

import navigation from "./navigation";
import "./sidebar.scss";


const ListItem = ({ title, svg, path, extra_classes = "" }) => {
	return ( 
		<NavLink
			to={ path }
			className={({ isActive }) => isActive ? "active " + extra_classes : extra_classes }
		> 
			{ svg }
			<span> { title } </span> 
		</NavLink>
	)
}


export default function Sidebar() {

	const checkPermission = useCheckPermission(); 

	return (
		<div className="sidebar">
			<nav className="sidebar-navigation">
				{ navigation.map(( item, i ) => {

               if ( item.group_name ) return (
                  <div key={ i }> { item.group_name } </div>
               )
					
					if ( item.available_for && !checkPermission( item.available_for ))
						return null;
					
					return (
						<ListItem
							key={ i }
							{...item }
						/>
					)
				})}
			</nav>
		</div>
	)
}